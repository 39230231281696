.about-us {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 30px;
    color: white;
  }
  
  .contributors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .contributor {
    margin: 20px;
    text-align: center;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .contributor h2 {
    margin-bottom: 5px;
  }
  
  .contributor p {
    margin: 0;
  }
  
  